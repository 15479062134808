import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import ReactMarkdown from 'react-markdown';

import Layout from '../components/Layout';

const Background = () => {
  const {
    markdownRemark: {
      frontmatter: { title, subtitle, section1, section2 },
    },
  } = useStaticQuery(graphql`
    query BackgroundPageQuery {
      markdownRemark(frontmatter: { pageName: { eq: "background" } }) {
        frontmatter {
          title
          subtitle
          section1 {
            content
            heading
          }
          section2 {
            description
            image1caption
            image2caption
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image2 {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            heading
          }
        }
      }
    }
  `);

  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{title}</h2>
            <p>{subtitle}</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <h3 className="major">{section1.heading}</h3>
            <ReactMarkdown source={section1.content} />
            <br />

            <h3 className="major">{section2.heading}</h3>
            <p>{section2.description}</p>

            <section className="features">
              <article>
                <a
                  href={section2.image.publicURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                >
                  <Img fluid={section2.image.childImageSharp.fluid} alt="" />
                </a>
                {/* <h3 className="major">{section2.image1caption}</h3> */}
                <p>{section2.image1caption}</p>
              </article>
              <article>
                <a
                  href={section2.image2.publicURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="image"
                >
                  <Img fluid={section2.image2.childImageSharp.fluid} alt="" />
                </a>
                {/* <h3 className="major">Nisl placerat</h3> */}
                <p>{section2.image2caption}</p>
              </article>
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Background;
